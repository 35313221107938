import React from 'react';
import Img  from 'gatsby-image';
import './style.css';

export const Coffee = (props) =>  {

console.log(props)
  return (
    <div className="box-model">
      <Img fixed={props.data} alt={props.alt}/>
      <div className="box-description">
        <p>{props.title}</p>
        <span>{props.description}</span>
      </div>
    </div>
  );
}
export default Coffee;