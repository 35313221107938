import React from 'react';

import Layout from '../../components/Layout/layout';
import Coffee from '../../components/Coffee/coffee'
import SEO from '../../components/seo';

function coffeePage() {
  return (
        <Layout>
            <SEO title="Coffee"/>
            <Coffee/>            
        </Layout>
    )
}

export default coffeePage;