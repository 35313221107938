import React from 'react';
import { useStaticQuery, graphql } from 'gatsby'
import './coffee.css'
import BoxModel from '../BoxModel/index'

export default () => {
  const data = useStaticQuery(graphql`
  query {
    
    superiorCoffee: file(relativePath: {eq: "cafe-superior.png"}) {
      childImageSharp {
        fixed(quality:100, width: 300, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    gourmetCoffee: file(relativePath: {eq: "cafe-gourmet.png"}) {
      childImageSharp {
        fixed(quality:100, width: 300, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    cafeinCoffee: file(relativePath: {eq: "cafe-descafeinado.png"}) {
      childImageSharp {
        fixed(quality:100, width: 300, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
  `)
  return (
      <section className="coffee-page">
          <div className="coffee-container">
            <BoxModel title="Café Superior" description="Grãos 100% arábicos, o blend compõe gãos Mineiros e Norte Paranaense 1kg" data={data.superiorCoffee.childImageSharp.fixed}/>
            <BoxModel title="Café Gourmet" description="Grãos 100% arábicos, o blend compõe gãos Mineiros e Norte Paranaense, Torra Premium 1kg" data={data.gourmetCoffee.childImageSharp.fixed}/>
            
            <BoxModel title="Café Descafeinado"description="Caixa com 20 saches" data={data.cafeinCoffee.childImageSharp.fixed}/>
          </div>
      </section>
  );
}
